<template>
    <div>
        <app-layout>
            <template v-slot:header>
	            <Header :title="$t('board_decisions')"
	                    @filter-div-status="datatable.filterStatus=$event">
	            </Header>
            </template>
	        <template v-slot:header-mobile>
	            <HeaderMobile :title="$t('board_decisions')"
	                          @filter-div-status="datatable.filterStatus=$event">
	            </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code">
                            </faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                v-model="datatable.queryParams.filter.semester_id" :setActive="true">
                            </semesters-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="4" lg="3">
                        <b-form-group :label="$t('date')">
                            <select-date v-model="datatable.queryParams.filter.date"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3">
                        <b-form-group :label="$t('subject')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.type" code="board_decision_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       @on-cell-click="onRowClick"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import qs from 'qs'
    import BoardDecisionService from "@/services/BoardDecisionService";
    import SelectDate from "@/components/interactive-fields/SelectDate";

    export default {
        components: {
            SelectDate,
            AppLayout,
            Header,
	        HeaderMobile,
            DatatableFilter,
            Datatable,
            FacultySelectbox,
            SemestersSelectbox,
            ParameterSelectbox,
        },
        metaInfo() {
            return {
                title: this.$t('board_decisions')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'boarddecision_update',
                                    callback: () => {

                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('faculty'),
                            field: ('faculty_name'),
                            sortable: false,
                        },
                        {
                            label: this.$t('date'),
                            field: 'date',
                            sortable: true,
                        },
                        {
                            label: this.$t('subject'),
                            field: ('type_text'),
                            sortable: false,
                        },
                        {
                            label: this.$t('decision_number'),
                            field: 'decision_number',
                            sortable: true,
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {
                        },
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                };
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return BoardDecisionService.getAll(config)
                                          .then((response) => {
                                              this.datatable.rows = response.data.data
                                              this.datatable.total = response.data.pagination.total
                                          })
                                          .finally(() => {
                                              this.datatable.isLoading = false;
                                          });
            },
            onRowClick(event){
                this.$router.push('/board/decisions/form/' + event.row.id);
            }
        }
    };
</script>

